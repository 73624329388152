import React from "react";
import Header from "../components/Header";
import LeftPanel from "../components/LeftPanel";
import "../css/SetupModal.module.css"
import "../css/SetupForm.module.css"
const Layout = ({ children }) => {
  return (
    <>
      <LeftPanel />
      <div className="right-side">
        <Header />
        {children}
      </div>
    </>
  );
};

export default Layout;
