import React, { useState, useMemo } from "react";
// import logo from "../images/logo.png";
import NavLink from "./NavLink";

import {
  AutoAwesomeMosaicOutlined as MasterIcon,
  AssessmentOutlined as ReportsIcon,
  FlashOn as QuickAccessIcon,
  SettingsOutlined as SettingsIcon,
  Home,
  PlayCircle,
  CancelRounded,
  Close,
  Add,
  ImageRounded,
} from "@mui/icons-material";

import { useLocation, useNavigate } from "react-router-dom";
import { brandName1 } from "../constants";

import styles from "../css/ReferenceScheme.module.css";

import { Box, Card, Paper, TextField, Typography } from "@mui/material";
import axios from "axios";
import { server } from "../constants";
let titleData = [
  { value: "report-orders", name: "Orders Report" },
  { value: "page5", name: "Menu and Seats" },
  { value: "page1", name: "Running Orders" },
  { value: "page2", name: "Order Details" },
  { value: "upload", name: "Upload" },
  { value: "Inv-Ac", name: "Vouchers" },
  { value: "Users", name: "User" },
  { value: "report-Customers", name: "Customers" },
  { value: "report-items", name: "Items Sales" },
  { value: "report-Order-tex", name: "Sales GST" },
  { value: "profit_lose_estimate", name: "Profit & Loss Estimate" },
  { value: "upi_payments", name: "UPI Payments" },
  { value: "setup", name: "Setup" },
];

const LeftPanel = ({}) => {
  const location = useLocation();
  const selectedPage = sessionStorage.getItem("selected-page") || "/page1";

  const Navigate = useNavigate();
  const page = window.location.pathname;

  document.title = useMemo(() => {
    let title = titleData?.find((a) => location.pathname.includes(a.value));
    return title?.name || brandName1;
  }, [location]);

  const [showLangSelectionPopup, setShowLangSelectionPopup] = useState();

  // console.log("outletDats", customerLinks)
  return (
    <>
      <div className="left-panel" style={{ position: "relative" }}>
        {/* <img src={logo} alt="" /> */}
        <div style={{ backgroundColor: "var(--main)" }}>
          <div
            className="header_toggle_btn"
            style={{ padding: "0 10px", height: "40px" }}
            onClick={() => {
              if (page.includes("Inv-Ac")) {
                Navigate("/Inv-Ac");
              } else {
                !page.includes(selectedPage) && Navigate(selectedPage);
              }
            }}
          >
            <Home className="icon_btn" style={{ fontSize: "34px" }} />
          </div>
        </div>
        <div className="nav">
          <>
            <NavLink
              title="Add"
              icon={<Add sx={{ fontSize: 40 }} />}
              href="/addNews"
              isActive={false}
              menuList={[]}
            />
            <NavLink
              title="Add Image"
              icon={<ImageRounded sx={{ fontSize: 40 }} />}
              href="/addImage"
              isActive={false}
              menuList={[]}
            />
            <NavLink
              title={"Master"}
              icon={<MasterIcon sx={{ fontSize: 40 }} />}
              isActive={true}
              menuList={[
                {
                  name: "News Category",
                  link: "/newCategory",
                },
              ]}
            />
            <NavLink
              title={"Reports"}
              icon={<ReportsIcon sx={{ fontSize: 40 }} />}
              isActive={false}
              menuList={[
                {
                  name: "Draft News",
                  link: "/draftNews",
                },
                {
                  name: "Published News",
                  link: "/PublishNews",
                },
              ]}
            />
            {/*<NavLink
              setIsItemAvilableOpen={setIsItemAvilableOpen}
              isItemAvilableOpen={isItemAvilableOpen}
              isMealSchedulingOpen={isMealSchedulingOpen}
              setIsMealSchedulingOpen={setIsMealSchedulingOpen}
              title={"Quick Access"}
              icon={<QuickAccessIcon sx={{ fontSize: 40 }} />}
              isActive={false}
              menuList={
                customerLinks.service_status === "Y"
                  ? [
                      {
                        name: "Item Availability",
                        link: "#",
                      },
                      {
                        name: "Meal Calendar",
                        link: "#",
                      },
                    ]
                  : [
                      {
                        name: "Item Availability",
                        link: "#",
                      },
                    ]
              }
            />
          
            */}
          </>
        </div>
      </div>
      {showLangSelectionPopup && (
        <SelectVideoLanguage close={() => setShowLangSelectionPopup(false)} />
      )}
    </>
  );
};

const SelectVideoLanguage = ({ close }) => {
  const Navigate = useNavigate();
  const [selected, setSelected] = useState();
  const onContinue = (i) => {
    setSelected(i);
    sessionStorage.setItem("selected-video-language", i?.toLowerCase());
    Navigate("/videos");
    close();
  };

  return (
    <>
      <div className={styles.popupContainer}>
        <div className={styles.popupBody} style={{ minWidth: "30vw" }}>
          <h2>Select Video Language</h2>
          <div></div>
          <CancelRounded onClick={close} className={styles.close} />
        </div>
      </div>
    </>
  );
};
const pricing = [
  { price: 9990, text: "1st Year", text2: "" },
  { price: 4500, text: "2nd Year Onwards", text2: "/ Year" },
];
const TrialMenu = ({ close, setNotification }) => {
  const [licencePopup, setLicencePopup] = useState();
  const [licence_key, setLicenceKey] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!licence_key) {
      return;
    }

    const response = await axios({
      method: "post",
      url: server + "/startup_verification/VarificationKey",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: { licence_key, outlet_uuid: localStorage.getItem("outlet_uuid") },
    });
    if (response.data.success) {
      console.log(response);
      localStorage.setItem("license_type", "Validating VPA");
      setNotification(true);
      setTimeout(() => setNotification(false), 3000);
      close();
    } else {
      setErrorMsg(response.data.message);
    }
  };
  return !licencePopup ? (
    <div className={styles.popupContainer} style={{ zIndex: 9999999999 }}>
      <div className={styles.popupBody} style={{ minWidth: "70vw" }}>
        <div
          onClick={close}
          style={{
            position: "absolute",
            color: "red",
            right: "0px",
            top: "0px",
            border: "none",
            width: "fit-content",
          }}
        >
          <Close />
        </div>
        <Box className="page-container">
          <div className="header-new">
            <span className="heading-new" style={{ height: "50%" }}>
              Simple Pricing
            </span>
            <span
              className="name"
              style={{
                justifyContent: "space-between",
                width: "100%",
                paddingTop: "20vh",
              }}
            >
              <button className="edit">Pay and Activate Now</button>
              <button className="edit" onClick={() => setLicencePopup(true)}>
                Enter License Key
              </button>
            </span>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              flex: "1",
              justifyContent: "center",
            }}
          >
            <div className="pricing-content">
              {pricing.map((a, i) => {
                return (
                  <Paper elevation={6} key={i}>
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "1rem",
                        background: "var(--main)",
                        color: "white",
                        width: 260,
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h4"
                        component="div"
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          fontWeight: "600",
                          fontSize: "4rem",
                        }}
                      >
                        ₹{a.price}{" "}
                        <Typography variant="body2">
                          &nbsp;{a?.text2}
                        </Typography>
                      </Typography>
                      <Typography variant="h5">{a.text}</Typography>
                    </Card>
                  </Paper>
                );
              })}
            </div>
          </div>
        </Box>
      </div>
    </div>
  ) : (
    <div className={styles.popupContainer} style={{ zIndex: 9999999999 }}>
      <div className={styles.popupBody} style={{ minWidth: "30vw" }}>
        <div
          onClick={() => setLicencePopup(false)}
          style={{
            position: "absolute",
            color: "red",
            right: "0px",
            top: "-20px",
            border: "none",
            width: "fit-content",
          }}
        >
          <Close />
        </div>
        <h2>Enter License Key</h2>
        <div>
          <TextField
            value={licence_key}
            label="License Key"
            variant="standard"
            style={{ width: "100%" }}
            onChange={(e) => setLicenceKey(e.target.value.replace(/\s+/g, ""))}
          />
          <i style={{ color: "red" }}>{errorMsg || ""}</i>
          <div className="name">
            <button className="edit" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
