import axios from "axios";
import React, { useEffect, useState } from "react";
import TextFeild from "../components/TextFeild";
import Layout from "./Layout";
import DOMPurify from "dompurify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { DeleteOutline } from "@mui/icons-material";
import ImageCropper from "../components/Cropper";
const AddImage = () => {
  const [data, setdata] = useState({});
  const [images, setImages] = useState();
  const [category, setCategory] = useState([]);
  const [videoTemp, setVideoTemp] = useState(true);
  const [errMassage, setErrorMassage] = useState("");
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
      setShowCropper(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCrop = (croppedImage) => {
    setImages(croppedImage);
    setShowCropper(false);
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const getItemsData = async () => {
    const response = await axios({
      method: "get",
      url: "/Category/getCategory",

      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) setCategory(response.data.result);
  };
  const getNew = async (news_id) => {
    const response = await axios({
      method: "get",
      url:
        (location.pathname.includes("editPublishedNews")
          ? "/news_published/getnews_published/"
          : "/news_draft/getnews_draft/") + news_id,

      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      setdata(response.data.result);
      if (response.data.result.img_url.length) {
        setVideoTemp(false);
      }
      if (response.data.result.desciption) {
        const blocksFromHtml = htmlToDraft(response.data.result.desciption);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  };
  useEffect(() => {
    if (params.news_id) {
      getNew(params.news_id);
    }
    getItemsData();
  }, [params.news_id]);

  const submitHandler = async (e, published) => {
    e.preventDefault();
    let postData = data;
    if (!images) {
      setErrorMassage("Please insert image");
      return;
    }
    if (images) {
      const mainThumbnailURL = await axios({
        url: "/s3Url",
        method: "get",
      });
      let UploadThumbnailURL = mainThumbnailURL.data.url;

      axios({
        url: UploadThumbnailURL,
        method: "put",
        headers: { "Content-Type": "multipart/form-data" },
        data: images,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
      let img_url = UploadThumbnailURL.split("?")[0];
      postData = {
        ...postData,
        img_data: img_url,
      };
    }
    const response = await axios({
      method: params.news_id ? "put" : "post",
      url: !published
        ? "/news_draft/" + (params.news_id ? "putnews_draft" : "postnews_draft")
        : "/news_published/" +
          (params.news_id ? "putnews_published" : "postnews_published"),
      data: postData,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      if (params.news_id) {
        navigate(-1);
      }
      setdata({});
      setImages();
      setEditorState(() => EditorState.createEmpty());
    }
  };
  const deleteImage = async (item, url) => {
    const response = await axios({
      method: "delete",
      url: "/news_published/deleteSigngleImage",
      data: { url, item },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      console.log(response);
      setdata((prev) => ({
        ...prev,
        img_url: prev.img_url.filter((a) => a !== url),
      }));
    }
  };
  var regex =
    /<iframe[^>]+src=["'](?:https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9_-]+))["'][^>]*>/g;

  console.log(videoTemp);
  useEffect(() => {
    if (document?.getElementById("myLink"))
      document
        ?.getElementById("myLink")
        ?.addEventListener("click", function (event) {
          event.preventDefault(); // Prevents the default action of following the link
          window.open(this.getAttribute("href"), "_blank"); // Opens the link in a new tab
        });
  });
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          height: "calc(100vh - 90px)",
          overflowY: "scroll",
        }}
      >
        <div
          className="content"
          style={{
            height: "fit-content",
            padding: "20px",
            width: "fit-content",
          }}
        >
          <div style={{ overflowY: "scroll" }}>
            <form className={"form"} onSubmit={submitHandler}>
              <div className={"row"}>
                <h1>Add News</h1>
              </div>

              <div
                className={"formGroup"}
                style={{ maxWidth: "90%", overflowX: "scroll" }}
              >
                <div
                  className={"row"}
                  style={{ position: "relative", alignItems: "start" }}
                >
                  {location.pathname.includes("editPublishedNews") ? (
                    <label
                      className="selectLabel flex"
                      style={{ position: "absolute", top: 0, right: "0" }}
                    >
                      Status
                      <input
                        type="checkbox"
                        name="category_title"
                        // className={"numberInput}
                        checked={data.status}
                        onChange={(e) =>
                          setdata({
                            ...data,
                            status: e.target.checked,
                          })
                        }
                        maxLength={60}
                      />
                    </label>
                  ) : (
                    ""
                  )}
                  <label className={"selectLabel"}>
                    News Title
                    <textarea
                      type="text"
                      name="category_title"
                      className={"numberInput"}
                      value={data?.title || ""}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          title: e.target.value,
                        })
                      }
                      style={{ height: "100px", width: "250px" }}
                      // maxLength={60}
                    />
                  </label>

                  <label className="selectLabel">
                    Category
                    <select
                      name="category_title"
                      className={"numberInput"}
                      value={data?.category_uuid || ""}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          category_uuid: e.target.value,
                        })
                      }
                    >
                      <option value="">None</option>
                      {category.map((a) => (
                        <option value={a.category_uuid}>
                          {a.category_title}
                        </option>
                      ))}
                    </select>
                  </label>

                  <label className={"selectLabel"}>
                    Files
                    <input
                      type="file"
                      name="category_title"
                      className={"numberInput"}
                      onChange={handleImageUpload}
                      accept="image/*"

                      // maxLength={60}
                    />
                  </label>
                </div>

                <div className={"row"} style={{ alignItems: "start" }}>
                  <div>
                    <h2>Preview</h2>
                    <br />
                   { console.log(images)}
                    {croppedImage && (
                      <div>
                        <img
                          src={croppedImage}
                          alt="Cropped"
                          style={{ maxWidth: "200px" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <i style={{ color: "red" }}>
                {errMassage === "" ? "" : "Error: " + errMassage}
              </i>
              <div className="flex">
                {!location.pathname.includes("editPublishedNews") ? (
                  <button type="submit" className={"submit"}>
                    Save Draft
                  </button>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  className={"submit"}
                  onClick={(e) => submitHandler(e, true)}
                >
                  Save & Publish
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showCropper && (
        <ImageCropper
          image={selectedImage}
          onCrop={handleCrop}
          onCancel={() => {
            setShowCropper(false);
          }}
          setPreviewImage={setCroppedImage}

        />
      )}
    </Layout>
  );
};

export default AddImage;
