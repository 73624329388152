import axios from "axios";
import {
  Navigate,
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import { server } from "./constants";
import "./index.css";
import AddNews from "./pages/AddNews";
import Dashboard from "./pages/Dashboard";
import Draftnews from "./pages/Main/Draft_news";
import NewCategory from "./pages/Main/NewCategory";
import LoginPage from "./LoginPage";
import AddImage from "./pages/AddImage";
const id = "211222";
function App() {
  axios.defaults.baseURL = server;
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          {localStorage.getItem("user_uuid") === id ? (
            <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/newCategory" element={<NewCategory />} />
              <Route path="/addNews" element={<AddNews />} />
              <Route path="/addImage" element={<AddImage />} />
              <Route path="/editNews/:news_id" element={<AddNews />} />
              <Route path="/editPublishedNews/:news_id" element={<AddNews />} />
              <Route path="/draftNews" element={<Draftnews />} />
              <Route path="/PublishNews" element={<Draftnews />} />
              <Route path="*" element={<Navigate to={"/dashboard"} />} />
            </>
          ) : !window.location.pathname.includes("/login") ? (
            <Route path="*" element={<Navigate replace to={"/login"} />} />
          ) : (
            ""
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
