import axios from "axios";
import React, { useEffect, useState } from "react";
import TextFeild from "../components/TextFeild";
import Layout from "./Layout";
import DOMPurify from "dompurify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { DeleteOutline } from "@mui/icons-material";
import DynamicContent from "../components/DynamicContent";
const AddNews = () => {
  const [data, setdata] = useState({});
  const [images, setImages] = useState([]);
  const [category, setCategory] = useState([]);
  const [videoTemp, setVideoTemp] = useState(true);
  const [errMassage, setErrorMassage] = useState("");
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }
  const getItemsData = async () => {
    const response = await axios({
      method: "get",
      url: "/Category/getCategory",

      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) setCategory(response.data.result);
  };
  const getNew = async (news_id) => {
    const response = await axios({
      method: "get",
      url:
        (location.pathname.includes("editPublishedNews")
          ? "/news_published/getnews_published/"
          : "/news_draft/getnews_draft/") + news_id,

      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      setdata(response.data.result);
      if (response.data.result.img_url.length) {
        setVideoTemp(false);
      }
      if (response.data.result.desciption) {
        const blocksFromHtml = htmlToDraft(response.data.result.desciption);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  };
  useEffect(() => {
    if (params.news_id) {
      getNew(params.news_id);
    }
    getItemsData();
  }, [params.news_id]);

  const submitHandler = async (e, published) => {
    e.preventDefault();
    let postData = data;
    if (!postData.title) {
      setErrorMassage("Please insert Title");
      return;
    }
    if (images.length) {
      for (let item of images) {
        const mainThumbnailURL = await axios({
          url: "/s3Url",
          method: "get",
        });
        let UploadThumbnailURL = mainThumbnailURL.data.url;

        axios({
          url: UploadThumbnailURL,
          method: "put",
          headers: { "Content-Type": "multipart/form-data" },
          data: item,
        })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => console.log(err));
        let img_url = UploadThumbnailURL.split("?")[0];
        postData = {
          ...postData,
          img_url: [...(postData.img_url || []), img_url],
        };
      }
    }
    const response = await axios({
      method: params.news_id ? "put" : "post",
      url: !published
        ? "/news_draft/" + (params.news_id ? "putnews_draft" : "postnews_draft")
        : "/news_published/" +
          (params.news_id ? "putnews_published" : "postnews_published"),
      data: postData,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      if (params.news_id) {
        navigate(-1);
      }
      setdata({});
      setImages([]);
      setEditorState(() => EditorState.createEmpty());
    }
  };
  const deleteImage = async (item, url) => {
    const response = await axios({
      method: "delete",
      url: "/news_published/deleteSigngleImage",
      data: { url, item },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      console.log(response);
      setdata((prev) => ({
        ...prev,
        img_url: prev.img_url.filter((a) => a !== url),
      }));
    }
  };
  var youtubeRegex =
  /<iframe[^>]+src=["'](?:https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9_-]+))["'][^>]*>/g;
  console.log("discriptions",data.desciption);

  console.log(videoTemp);
  useEffect(() => {
    if (document?.getElementById("myLink"))
      document
        ?.getElementById("myLink")
        ?.addEventListener("click", function (event) {
          event.preventDefault(); // Prevents the default action of following the link
          window.open(this.getAttribute("href"), "_blank"); // Opens the link in a new tab
        });
  });
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          height: "calc(100vh - 90px)",
          overflowY: "scroll",
        }}
      >
        <div
          className="content"
          style={{
            height: "fit-content",
            padding: "20px",
            width: "fit-content",
          }}
        >
          <div style={{ overflowY: "scroll" }}>
            <form className={"form"} onSubmit={submitHandler}>
              <div className={"row"}>
                <h1>Add News</h1>
              </div>

              <div
                className={"formGroup"}
                style={{ maxWidth: "90%", overflowX: "scroll" }}
              >
                <div
                  className={"row"}
                  style={{ position: "relative", alignItems: "start" }}
                >
                  {location.pathname.includes("editPublishedNews") ? (
                    <label
                      className="selectLabel flex"
                      style={{ position: "absolute", top: 0, right: "0" }}
                    >
                      Status
                      <input
                        type="checkbox"
                        name="category_title"
                        // className={"numberInput}
                        checked={data.status}
                        onChange={(e) =>
                          setdata({
                            ...data,
                            status: e.target.checked,
                          })
                        }
                        maxLength={60}
                      />
                    </label>
                  ) : (
                    ""
                  )}
                  <label className={"selectLabel"}>
                    News Title
                    <textarea
                      type="text"
                      name="category_title"
                      className={"numberInput"}
                      value={data?.title || ""}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          title: e.target.value,
                        })
                      }
                      style={{ height: "100px", width: "250px" }}
                      // maxLength={60}
                    />
                  </label>

                  <label className={"selectLabel"}>
                    News Brief
                    <textarea
                      type="text"
                      name="category_title"
                      className={"numberInput"}
                      value={data?.brief || ""}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          brief: e.target.value,
                        })
                      }
                      style={{ height: "100px", width: "250px" }}
                      // maxLength={60}
                    />
                  </label>
                  <label className="selectLabel">
                    Category
                    <select
                      name="category_title"
                      className={"numberInput"}
                      value={data?.category_uuid || ""}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          category_uuid: e.target.value,
                        })
                      }
                    >
                      <option value="">None</option>
                      {category.map((a) => (
                        <option value={a.category_uuid}>
                          {a.category_title}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="category_title"
                      // className={"numberInput}
                      checked={videoTemp}
                      onClick={(e) => setVideoTemp(true)}
                      maxLength={60}
                    />
                    Videos
                  </label>
                  <label>
                    {/* <select
                      name="category_title"
                      className={"numberInput}
                      value={videoTemp}
                      onChange={(e) => setVideoTemp(e.target.value)}
                      >
                      <option value={true}>Videos</option>
                      <option value={false}>Images</option>
                    </select> */}
                    <input
                      type="radio"
                      name="category_title"
                      // className={"numberInput}
                      checked={!videoTemp}
                      onClick={(e) => {
                        setVideoTemp(false);
                        setdata((prev) => ({ ...prev, yt_video_id: "" }));
                      }}
                      // maxLength={60}
                    />
                    Images
                  </label>
                  {videoTemp ? (
                    <label className={"selectLabel"}>
                      Video Id
                      <input
                        type="text"
                        name="category_title"
                        className={"numberInput"}
                        value={data?.yt_video_id || ""}
                        onChange={(e) =>
                          setdata({
                            ...data,
                            yt_video_id: e.target.value,
                          })
                        }
                        // maxLength={60}
                      />
                    </label>
                  ) : (
                    <>
                      <label className={"selectLabel"} htmlFor="image_files">
      Files
      <button
        className="submit"
        type="button"
        onClick={() => document.getElementById('image_files').click()}
      >
        + Image
      </button>
      <input
        type="file"
        id="image_files"
        className={"numberInput"}
        onChange={(e) =>
          setImages((prev) =>
            e.target.files[0] ? [...prev, e.target.files[0]] : prev
          )
        }
        accept="image/*"
        style={{ display: "none" }}
      />
    </label>
                      {console.log({images})}
                      {images.map((item) => (
                        <div style={{ position: "relative" }} key={item.name}>
                          <img
                            src={URL.createObjectURL(item)}
                            alt=""
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "contain",
                            }}
                          />
                          <div
                            style={{
                              color: "red",
                              position: "absolute",
                              top: "0",
                              right: "0",
                            }}
                            onClick={
                              () =>
                                setImages((prev) =>
                                  prev.filter((a) => a.name !== item.name)
                                )
                              // console.log(item.name)
                            }
                          >
                            <DeleteOutline />
                          </div>
                        </div>
                      ))}
                      {data?.img_url?.map((item) => (
                        <div style={{ position: "relative" }} key={item.name}>
                          <img
                            src={item}
                            alt=""
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "contain",
                            }}
                          />
                          <div
                            style={{
                              color: "red",
                              position: "absolute",
                              top: "0",
                              right: "0",
                            }}
                            onClick={
                              () => deleteImage(data, item)
                              // console.log(item.name)
                            }
                          >
                            <DeleteOutline />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>

                <div className={"row"} style={{ alignItems: "start" }}>
                  <div style={{maxWidth:"50%"}}>
                  <TextFeild
                    convertedContent={data.desciption}
                    setConvertedContent={(e) =>
                      setdata((prev) => ({ ...prev, desciption: e }))
                    }
                    editorState={editorState}
                    setEditorState={setEditorState}
                  /></div>
                  <div>
                    <h2>Preview</h2>
                    <br />
                    <DynamicContent content={data.desciption} />
                    {/* <div
                      className="preview"
                      dangerouslySetInnerHTML={createMarkup(
                        (data?.desciption || "")
                          .replace(/<p><\/p>/g, "<br/>")
                          .replace(/\n/g, "<br/>")

                          .replace(
                            /<ul>/g,
                            '<div style={{ display: "flex", flexDirection: "column" }}>'
                          )

                          .replace(/<\/ul>/g, "</div>")
                          .replace(/target="_blank"/g, 'id="myLink" ')
                          .replace(youtubeRegex, function (match, videoId) {
                            return (
                              '<iframe width="100%" height="315" src="https://www.youtube.com/embed/' +
                              videoId +
                              '?si=7rYbHumS3MhWgATk" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>'
                            );
                          })
                          .replace(/<img/g, '<img style="width:100%" ')
                      )}
                    ></div> */}
                  </div>
                </div>
              </div>
              <i style={{ color: "red" }}>
                {errMassage === "" ? "" : "Error: " + errMassage}
              </i>
              <div className="flex">
                {!location.pathname.includes("editPublishedNews") ? (
                  <button type="submit" className={"submit"}>
                    Save Draft
                  </button>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  className={"submit"}
                  onClick={(e) => submitHandler(e, true)}
                >
                  Save & Publish
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddNews;
