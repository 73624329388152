/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useEffect, useState, useRef } from "react";



import "../css/header.css";
import axios from "axios";

import Logout from "./Logout";

import {  LogoutOutlined } from "@mui/icons-material";
import { brandName1, server } from "../constants";



const Header = () => {

  const [dropdown, setDropdown] = useState(false);
  
  const [logoutPopup, setLogoutPopup] = useState(false);
  const ref = useRef();



  // const selectedPage = sessionStorage.getItem("selected-page") || "/page1";
  const [services, setServices] = useState([
    { name: "Dine In", id: "Dine-in", status: false, serviceStatus: "" },
    { name: "Delivery", id: "Delivery", status: false, serviceStatus: "" },
    { name: "Take Away", id: "Pick-up", status: false, serviceStatus: "" },
    { name: "Meals", id: "Schedule", status: false, serviceStatus: "" },
    { name: "Zomato", id: "Zomato", status: false, serviceStatus: "" },
    { name: "Swiggy", id: "Swiggy", status: false, serviceStatus: "" },
  ]);

  
  // console.log("page", location.pathname)

  const getData = () => {
    var apiResponse;
    const data = JSON.stringify([
      {
        outlet_uuid: localStorage.getItem("outlet_uuid"),
      },
    ]);

    const config = {
      method: "post",
      url: server + "/outlet/GetServiceStatus",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        apiResponse = response.data.result;
        console.log("api resonse", apiResponse);
        setServices((prevState) => [
          ...prevState.map((item) => {
            const [{ button_status, service_status }] = apiResponse.filter(
              (e) => e.service_name === item.id
            );
            return {
              ...item,
              status: parseInt(button_status) > 0 ? true : false,
              serviceStatus: service_status,
            };
          }),
        ]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };




  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the dropdown is open and the clicked target is not within the dropdown,
      // then close the menu
      if (dropdown && ref.current && !ref.current.contains(e.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropdown]);

  return (
    <div className="header">
      <h2>{brandName1}</h2>
      <div className="header_right">
        <div
          style={{
            hight: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "15px",
            marginRight: "10px",
          }}
        >
          <div>
           
          </div>
        </div>
        
       
        <div className="header_toggle_btn" onClick={() => setLogoutPopup(true)}>
          <LogoutOutlined className="icon_btn" />
        </div>
      </div>
      {logoutPopup ? <Logout onClose={() => setLogoutPopup(false)} /> : ""}
    </div>
  );
};

export default Header;