import React, { useState, useEffect } from "react";
import "../css/Logout.css";
import CloseIcon from "@mui/icons-material/Close";

import { brandName1 } from "../constants";
import { useNavigate } from "react-router-dom";

const Logout = ({ onClose }) => {
  const [logoutHover, setLogoutHover] = useState(false);
  const [isClearLoding, setIsClearLoding] = useState(false);
  const [message, setMessage] = useState("");
  const [messageTwo, setMessageTwo] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const navigate = useNavigate();
  const SimpleLogout = () => {
    setLoading(true);
    localStorage.setItem("Simple_logout", "0");
    sessionStorage.removeItem("token");
    window.location.pathname = "/login";
  };

  const ClearLogout = () => {
    setIsClearLoding(true);
    sessionStorage.clear();
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <div className="logoutPopup">
        <div className="logout_content">
          <CloseIcon className="button_Close" onClick={() => onClose()} />
          <h1>Logout</h1>
          <div className="logout_button">
            <div className="button_container">
              {!loading ? (
                <button className="simple_Logout_button" onClick={ClearLogout}>
                  Confirm
                </button>
              ) : (
                <button className="clear_Logout_button" id="loading-screen">
                  <svg viewBox="0 0 100 100">
                    <path
                      d="M10 50A40 40 0 0 0 90 50A40 44.8 0 0 1 10 50"
                      fill="var(--white)"
                      stroke="none"
                    >
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        dur="1s"
                        repeatCount="indefinite"
                        keyTimes="0;1"
                        values="0 50 51;360 50 51"
                      ></animateTransform>
                    </path>
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {confirmPopup ? (
        <ConfirmMessagePopup
          ClearLogout={ClearLogout}
          isClearLoding={isClearLoding}
          setConfirmPopup={setConfirmPopup}
          message={message}
          messageTwo={messageTwo}
        />
      ) : (
        ""
      )}
    </>
  );
};
const ConfirmMessagePopup = ({
  ClearLogout,
  isClearLoding,
  setConfirmPopup,
  message,
  messageTwo,
  clearFBDeviceId,
}) => {
  return (
    <div className="logoutPopup" style={{ zIndex: "9999" }}>
      <div className="logout_content">
        <h1 style={{ color: "red" }}>Warning!!!</h1>
        {message ? <div>{message}</div> : ""}
        {messageTwo ? <div>{messageTwo}</div> : ""}

        <div className="logout_button">
          <div className="button_container">
            {!isClearLoding ? (
              <button
                style={{ background: "red" }}
                className="clear_Logout_button"
                onClick={() => {
                  clearFBDeviceId(ClearLogout);
                }}
              >
                Confirm
              </button>
            ) : (
              <button className="clear_Logout_button" id="loading-screen">
                <svg viewBox="0 0 100 100">
                  <path
                    d="M10 50A40 40 0 0 0 90 50A40 44.8 0 0 1 10 50"
                    fill="var(--white)"
                    stroke="none"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      dur="1s"
                      repeatCount="indefinite"
                      keyTimes="0;1"
                      values="0 50 51;360 50 51"
                    ></animateTransform>
                  </path>
                </svg>
              </button>
            )}
          </div>
          <div className="button_container">
            <button
              className="simple_Logout_button"
              onClick={() => setConfirmPopup(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Logout;
