import React, { useState, useEffect } from "react";

import {
  ArrowDropDown,
  ArrowDropUp,
  CopyAllOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import axios from "axios";
import Layout from "../Layout";
import "../../css/SetupForm.module.css";
import { useLocation, useNavigate } from "react-router-dom";

const Draft_news = () => {
  const [itemsData, setItemsData] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [filterItemsData, setFilterItemsData] = useState([]);

  const [popupForm, setPopupForm] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  const [filterCategory, setFilterCategory] = useState("");
  const location = useLocation();
  const getItemsData = async () => {
    const response = await axios({
      method: "get",
      url: location.pathname.includes("PublishNews")
        ? "/news_published/getnews_published"
        : "/news_draft/getnews_draft",

      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      setItemsData(response.data.result);
    }
  };
  useEffect(() => {
    getItemsData();
  }, [popupForm, location]);
  useEffect(
    () =>
      setFilterItemsData(
        itemsData
          .filter((a) => a.title)

          .filter(
            (a) =>
              !filterCategory ||
              a.title
                .toLocaleLowerCase()
                .includes(filterCategory.toLocaleLowerCase())
          )
      ),
    [itemsData, filterCategory]
  );

  return (
    <Layout>
      <div style={{ display: "flex", height: "calc(100vh - 90px)" }}>
        <div className="item-sales-container orders-report-container">
          <div id="heading">
            <h2>
              {location.pathname.includes("PublishNews")
                ? "Published News"
                : "Draft News"}
            </h2>
          </div>
          <div id="item-sales-top">
            <div
              id="date-input-container"
              style={{
                overflow: "visible",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <input
                type="text"
                onChange={(e) => setFilterCategory(e.target.value)}
                value={filterCategory}
                placeholder="Search Title..."
                className="searchInput"
              />
              <div>Total Items: {filterItemsData.length}</div>
            </div>
          </div>
          <div className="table-container-user item-sales-container">
            <Table
              itemsDetails={filterItemsData}
              setPopupForm={setPopupForm}
              setDeletePopup={setDeletePopup}
              location={location}
              setSuccessMessage={setSuccessMessage}
            />
          </div>
        </div>
      </div>
      {popupForm ? (
        <NewUserForm
          onSave={() => setPopupForm(false)}
          setItemsData={setItemsData}
          popupInfo={popupForm}
          items={itemsData}
        />
      ) : (
        ""
      )}
      {deletePopup ? (
        <DeleteItemPopup
          onSave={() => setDeletePopup(false)}
          setItemsData={setItemsData}
          popupInfo={deletePopup}
          location={location}
        />
      ) : (
        ""
      )}
      {successMessage ? (
        <div
          style={{
            position: "absolute",
            color: "green",
            bottom: "10vh",
            width: "100vw",
            textAlign: "center",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              padding: "5px 10px",
              background: "green",
              color: "white",
              borderRadius: "10px",
              width: "fit-content",
            }}
          >
            {successMessage}
          </div>
        </div>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default Draft_news;

function Table({ itemsDetails, location, setDeletePopup, setSuccessMessage }) {
  const [items, setItems] = useState("sort_order");
  const [order, setOrder] = useState("");

  const navigate = useNavigate();
  console.log(items);
  function formatAMPM(date) {
    var weekdays = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var r = weekdays[date.getDay()];
    var Month = monthNames[date.getMonth()];
    var dateNumber = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime =
      r +
      " " +
      dateNumber +
      " " +
      Month +
      " " +
      date.getFullYear() +
      " " +
      hours +
      ":" +
      minutes +
      " " +
      ampm;
    return strTime;
  }
  return (
    <table
      className="user-table"
      style={{ maxWidth: "100vw", height: "fit-content", overflowX: "scroll" }}
    >
      <thead>
        <tr>
          <th>S.N</th>
          <th colSpan={3}>
            <div className="t-head-element">
              <span> Title</span>
              <div className="sort-buttons-container">
                <button
                  onClick={() => {
                    setItems("title");
                    setOrder("asc");
                  }}
                >
                  <ArrowDropUp className="sort-up sort-button" />
                </button>
                <button
                  onClick={() => {
                    setItems("title");
                    setOrder("desc");
                  }}
                >
                  <ArrowDropDown className="sort-down sort-button" />
                </button>
              </div>
            </div>
          </th>

          <th colSpan={2}>
            <div className="t-head-element">
              <span>Brief</span>
              <div className="sort-buttons-container">
                <button
                  onClick={() => {
                    setItems("brief");
                    setOrder("asc");
                  }}
                >
                  <ArrowDropUp className="sort-up sort-button" />
                </button>
                <button
                  onClick={() => {
                    setItems("brief");
                    setOrder("desc");
                  }}
                >
                  <ArrowDropDown className="sort-down sort-button" />
                </button>
              </div>
            </div>
          </th>
          <th colSpan={2}>
            <div className="t-head-element">
              <span>Created At</span>
              <div className="sort-buttons-container">
                <button
                  onClick={() => {
                    setItems("created_at");
                    setOrder("asc");
                  }}
                >
                  <ArrowDropUp className="sort-up sort-button" />
                </button>
                <button
                  onClick={() => {
                    setItems("created_at");
                    setOrder("desc");
                  }}
                >
                  <ArrowDropDown className="sort-down sort-button" />
                </button>
              </div>
            </div>
          </th>
          <th colSpan={2}></th>
        </tr>
      </thead>
      <tbody className="tbody">
        {itemsDetails
          .map((a) => ({ ...a, item_discount: +a.item_discount || 0 }))
          .sort((a, b) =>
            order === "asc"
              ? typeof a[items] === "string"
                ? a[items]?.localeCompare(b[items])
                : a[items] - b[items]
              : typeof a[items] === "string"
              ? b[items]?.localeCompare(a[items])
              : b[items] - a[items]
          )
          ?.map((item, i) => (
            <tr
              key={Math.random()}
              style={{ height: "30px" }}
              onClick={() =>
                navigate(
                  (location.pathname.includes("PublishNews")
                    ? "/editPublishedNews/"
                    : "/editNews/") + item.news_id
                )
              }
            >
              <td>{i + 1}</td>

              <td colSpan={3}>{item.title}</td>

              <td colSpan={2}>{item.brief}</td>
              <td colSpan={2}>{formatAMPM(new Date(item.created_at))}</td>
              <td
                colSpan={1}
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(
                    `https://gondiakhabar.com/${
                      location.pathname.includes("PublishNews")
                        ? "app"
                        : "draft"
                    }/` + item.news_id
                  );
                  setSuccessMessage("Copied");
                  setTimeout(() => {
                    setSuccessMessage("");
                  }, 2000);
                }}
              >
                <CopyAllOutlined />
              </td>
              <td
                colSpan={1}
                onClick={(e) => {
                  e.stopPropagation();
                  setDeletePopup(item);
                }}
              >
                <DeleteOutline />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
function NewUserForm({
  onSave,
  popupInfo,
  setItemsData,

  items,
}) {
  const [data, setdata] = useState({});

  const [errMassage, setErrorMassage] = useState("");

  useEffect(() => {
    if (popupInfo?.type === "edit")
      setdata({
        status: 1,
        ...popupInfo.data,
      });
    else
      setdata({
        category_title: "",
        status: 1,
      });
  }, [popupInfo.data, popupInfo?.type]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!data.category_title) {
      setErrorMassage("Please insert Category Title");
      return;
    }

    if (popupInfo?.type === "edit") {
      const response = await axios({
        method: "put",
        url: "/Category/putCategory",
        data: [data],
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.result[0].success) {
        setItemsData((prev) =>
          prev.map((i) => (i.user_uuid === data.user_uuid ? data : i))
        );
        onSave();
      }
    } else {
      if (
        data?.item_code &&
        items.find((a) => a.item_code === data.item_code)
      ) {
        setErrorMassage("Please insert Different Item Code");
        return;
      }
      const response = await axios({
        method: "post",
        url: "/Category/postCategory",
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.success) {
        setItemsData((prev) => [...prev, data]);
        onSave();
      }
    }
  };

  return (
    <div className="overlay">
      <div className="modal" style={{ height: "70vh", width: "fit-content" }}>
        <div
          className="content"
          style={{
            height: "fit-content",
            padding: "20px",
            width: "fit-content",
          }}
        >
          <div style={{ overflowY: "scroll" }}>
            <form className="form" onSubmit={submitHandler}>
              <div className="row">
                <h1>{popupInfo.type === "edit" ? "Edit" : "Add"} Items</h1>
              </div>

              <div className="formGroup">
                <div className="row">
                  <label className="selectLabel">
                    Category Title
                    <input
                      type="text"
                      name="category_title"
                      className="numberInput"
                      value={data?.category_title}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          category_title: e.target.value,
                        })
                      }
                      maxLength={60}
                    />
                  </label>
                </div>

                <div className="row">
                  <label className="selectLabel">
                    Status
                    <div
                      className="flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="flex">
                        <input
                          type="radio"
                          name="sort_order"
                          className="numberInput"
                          checked={data.status}
                          style={{ height: "25px" }}
                          onClick={() =>
                            setdata((prev) => ({ ...prev, status: 1 }))
                          }
                        />
                        On
                      </div>
                      <div className="flex">
                        <input
                          type="radio"
                          name="sort_order"
                          className="numberInput"
                          checked={!data.status}
                          style={{ height: "25px" }}
                          onClick={() =>
                            setdata((prev) => ({ ...prev, status: 0 }))
                          }
                        />
                        Off
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <i style={{ color: "red" }}>
                {errMassage === "" ? "" : "Error: " + errMassage}
              </i>

              <button type="submit" className="submit">
                Save changes
              </button>
            </form>
          </div>
          <button onClick={onSave} className="closeButton">
            x
          </button>
        </div>
      </div>
    </div>
  );
}
function DeleteItemPopup({ onSave, popupInfo, setItemsData, location }) {
  const [errMassage, setErrorMassage] = useState("");
  const [loading, setLoading] = useState(false);
  console.log(popupInfo);
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios({
        method: "delete",
        url: location.pathname.includes("PublishNews")
          ? "/news_published/deletenews_published"
          : "/news_draft/deletenews_draft",
        data: { news_id: popupInfo.news_id },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.success) {
        setItemsData((prev) =>
          prev.filter((i) => i.news_id !== popupInfo.news_id)
        );
        onSave();
      }
    } catch (err) {
      console.log(err);
      setErrorMassage("Order already exist");
    }
    setLoading(false);
  };

  return (
    <div className="overlay">
      <div className="modal" style={{ width: "fit-content" }}>
        <div
          className="content"
          style={{
            height: "fit-content",
            padding: "20px",
            width: "fit-content",
          }}
        >
          <div style={{ overflowY: "scroll" }}>
            <form className="form" onSubmit={submitHandler}>
              <div className="row">
                <h1>Delete Items</h1>
              </div>
              <div className="row">
                <h1>{popupInfo.category_title}</h1>
              </div>

              <i style={{ color: "red" }}>
                {errMassage === "" ? "" : "Error: " + errMassage}
              </i>
              <div className="flex" style={{ justifyContent: "space-between" }}>
                {loading ? (
                  <button
                    className="submit"
                    id="loading-screen"
                    style={{ background: "red", width: "120px" }}
                  >
                    <svg viewBox="0 0 100 100">
                      <path
                        d="M10 50A40 40 0 0 0 90 50A40 44.8 0 0 1 10 50"
                        fill="#ffffff"
                        stroke="none"
                      >
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          dur="1s"
                          repeatCount="indefinite"
                          keyTimes="0;1"
                          values="0 50 51;360 50 51"
                        ></animateTransform>
                      </path>
                    </svg>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="submit"
                    style={{ background: "red" }}
                  >
                    Confirm
                  </button>
                )}
                <button type="button" className="submit" onClick={onSave}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
