import React, { useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const ImageCropper = ({ image, onCrop, onCancel,setPreviewImage }) => {
  const cropperRef = useRef(null);
  const handlePreviewCrop = () => {
    const cropper = cropperRef.current.cropper;
    const canvas = cropper.getCroppedCanvas();

    // Extract the original format
    const originalFormat = image.split(';')[0].split('/')[1];

    // Get the cropped image in the original format
    const croppedImage = canvas.toDataURL(`image/${originalFormat}`);
    setPreviewImage(croppedImage);
  };

  const handleCrop = () => {
    handlePreviewCrop();
    const cropper = cropperRef.current.cropper;
    const canvas = cropper.getCroppedCanvas();
  
    // Extract the original format
    const originalFormat = image.split(';')[0].split('/')[1];
  
    // Convert the cropped image to base64
    const croppedBase64 = canvas.toDataURL(`image/${originalFormat}`);
  
    // Convert base64 to Blob
    const byteString = atob(croppedBase64.split(',')[1]);
    const mimeString = croppedBase64.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
  
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
  
    const blob = new Blob([ab], { type: mimeString });
  
    // You can now upload `blob` to S3
    onCrop(blob);
  };
  

  return (
    <div style={styles.overlay}>
      <div style={styles.cropperContainer}>
        <Cropper
          src={image}
          style={{ height: 400, width: '100%' }}
          initialAspectRatio={9 / 16} // Mobile ratio
          aspectRatio={9 / 16} // Mobile ratio
          guides={false}
          ref={cropperRef}
          viewMode={1}
        />
        <div style={styles.buttonContainer}>
          <button style={styles.button} onClick={handleCrop}>Crop Image</button>
          <button style={{ ...styles.button, backgroundColor: '#f44336' }} onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  cropperContainer: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)',
    maxWidth: '500px',
    width: '100%',
  },
  buttonContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s',
  }
};

export default ImageCropper;
