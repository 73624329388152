import React, { useState, useEffect } from "react";

import { ArrowDropDown, ArrowDropUp, DeleteOutline } from "@mui/icons-material";
import axios from "axios";
import Layout from "../Layout";
import "../../css/SetupForm.module.css"
const NewCategory = () => {
  const [itemsData, setItemsData] = useState([]);
  const [disabledItem, setDisabledItem] = useState(false);

  const [filterItemsData, setFilterItemsData] = useState([]);

  const [popupForm, setPopupForm] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  const [filterCategory, setFilterCategory] = useState("");


  const getItemsData = async () => {
    const response = await axios({
      method: "get",
      url: "/Category/getCategory",

      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) setItemsData(response.data.result);
  };
  useEffect(() => {
    getItemsData();
  }, [popupForm,deletePopup,popupForm]);
  useEffect(
    () =>
      setFilterItemsData(
        itemsData
          .filter((a) => a.category_title)
          .filter((a) => disabledItem || a.status)

          .filter(
            (a) =>
              !filterCategory ||
              a.category_title
                .toLocaleLowerCase()
                .includes(filterCategory.toLocaleLowerCase())
          )
      ),
    [itemsData, filterCategory, disabledItem]
  );

  return (
    <Layout>
      <div style={{ display: "flex", height: "calc(100vh - 90px)" }}>
        <div className="item-sales-container orders-report-container">
          <div id="heading">
            <h2>News Category</h2>
          </div>
          <div id="item-sales-top">
            <div
              id="date-input-container"
              style={{
                overflow: "visible",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <input
                type="text"
                onChange={(e) => setFilterCategory(e.target.value)}
                value={filterCategory}
                placeholder="Search Category..."
                className="searchInput"
              />
              <div>Total Items: {filterItemsData.length}</div>
              <div
                style={{
                  display: "flex",
                  width: "120px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <input
                  type="checkbox"
                  onChange={(e) => setDisabledItem(e.target.checked)}
                  value={disabledItem}
                  className="searchInput"
                  style={{ scale: "1.2" }}
                />
                <div style={{ width: "100px" }}>Disabled Items</div>
              </div>

              <button
                className="item-sales-search"
                onClick={() => setPopupForm(true)}
              >
                Add
              </button>
            </div>
          </div>
          <div className="table-container-user item-sales-container">
            <Table
              itemsDetails={filterItemsData}
              setPopupForm={setPopupForm}
              setDeletePopup={setDeletePopup}
            />
          </div>
        </div>
      </div>
      {popupForm ? (
        <NewUserForm
          onSave={() => setPopupForm(false)}
          setItemsData={setItemsData}
         
          popupInfo={popupForm}
          items={itemsData}
        />
      ) : (
        ""
      )}
      {deletePopup ? (
        <DeleteItemPopup
          onSave={() => setDeletePopup(false)}
          setItemsData={setItemsData}
          popupInfo={deletePopup}
        />
      ) : (
        ""
      )}
    </Layout>
  );
};

export default NewCategory;

function Table({ itemsDetails, setPopupForm, setDeletePopup }) {
  const [items, setItems] = useState("sort_order");
  const [order, setOrder] = useState("");

  console.log(items);
  return (
    <table
      className="user-table"
      style={{ maxWidth: "100vw", height: "fit-content", overflowX: "scroll" }}
    >
      <thead>
        <tr>
          <th>S.N</th>
          <th colSpan={3}>
            <div className="t-head-element">
              <span>Category Title</span>
              <div className="sort-buttons-container">
                <button
                  onClick={() => {
                    setItems("category_title");
                    setOrder("asc");
                  }}
                >
                  <ArrowDropUp className="sort-up sort-button" />
                </button>
                <button
                  onClick={() => {
                    setItems("category_title");
                    setOrder("desc");
                  }}
                >
                  <ArrowDropDown className="sort-down sort-button" />
                </button>
              </div>
            </div>
          </th>

          <th colSpan={2}>
            <div className="t-head-element">
              <span>Status</span>
              <div className="sort-buttons-container">
                <button
                  onClick={() => {
                    setItems("status");
                    setOrder("asc");
                  }}
                >
                  <ArrowDropUp className="sort-up sort-button" />
                </button>
                <button
                  onClick={() => {
                    setItems("status");
                    setOrder("desc");
                  }}
                >
                  <ArrowDropDown className="sort-down sort-button" />
                </button>
              </div>
            </div>
          </th>
          <th colSpan={2}>
            <div className="t-head-element">
              <span>Sort Order</span>
              <div className="sort-buttons-container">
                <button
                  onClick={() => {
                    setItems("sort_order");
                    setOrder("asc");
                  }}
                >
                  <ArrowDropUp className="sort-up sort-button" />
                </button>
                <button
                  onClick={() => {
                    setItems("sort_order");
                    setOrder("desc");
                  }}
                >
                  <ArrowDropDown className="sort-down sort-button" />
                </button>
              </div>
            </div>
          </th>
          <th colSpan={1}></th>
        </tr>
      </thead>
      <tbody className="tbody">
        {itemsDetails
          .map((a) => ({ ...a, item_discount: +a.item_discount || 0 }))
          .sort((a, b) =>
            order === "asc"
              ? typeof a[items] === "string"
                ? a[items]?.localeCompare(b[items])
                : a[items] - b[items]
              : typeof a[items] === "string"
              ? b[items]?.localeCompare(a[items])
              : b[items] - a[items]
          )
          ?.map((item, i) => (
            <tr
              key={Math.random()}
              style={{ height: "30px" }}
              onClick={() => setPopupForm({ type: "edit", data: item })}
            >
              <td>{i + 1}</td>

              <td colSpan={3}>{item.category_title}</td>

              <td colSpan={2}>{item.status}</td>
              <td colSpan={2}>{item.sort_order}</td>
              <td
                colSpan={1}
                onClick={(e) => {
                  e.stopPropagation();

                  setDeletePopup(item);
                }}
              >
                <DeleteOutline />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
function NewUserForm({
  onSave,
  popupInfo,
  setItemsData,
  
  items,
}) {
  const [data, setdata] = useState({});

  const [errMassage, setErrorMassage] = useState("");
 
  useEffect(() => {
    if (popupInfo?.type === "edit")
      setdata({
        status: 1,
        ...popupInfo.data,
      });
    else
      setdata({
        category_title: "",
        status: 1,
      });
  }, [ popupInfo.data, popupInfo?.type]);

  const submitHandler = async (e) => {
    e.preventDefault();

    
    if (!data.category_title) {
      setErrorMassage("Please insert Category Title");
      return;
    }
 

    if (popupInfo?.type === "edit") {
      const response = await axios({
        method: "put",
        url: "/Category/putCategory",
        data: [data],
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.result[0].success) {
      
        onSave();
      }
    } else {
      if (
        data?.item_code &&
        items.find((a) => a.item_code === data.item_code)
      ) {
        setErrorMassage("Please insert Different Item Code");
        return;
      }
      const response = await axios({
        method: "post",
        url: "/Category/postCategory",
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.success) {
    
        onSave();
      }
    }
  };

  return (
    <div className="overlay">
      <div className="modal" style={{ height: "fit-content", width: "fit-content" }}>
        <div
          className="content"
          style={{
            height: "fit-content",
            padding: "20px",
            width: "fit-content",
          }}
        >
          <div style={{ overflowY: "scroll" }}>
            <form className="form" onSubmit={submitHandler}>
              <div className="row">
                <h1>{popupInfo.type === "edit" ? "Edit" : "Add"} Items</h1>
              </div>

              <div className="formGroup">
                <div className="row">
                  <label className="selectLabel">
                    Category Title
                    <input
                      type="text"
                      name="category_title"
                      className="numberInput"
                      value={data?.category_title}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          category_title: e.target.value,
                        
                        })
                      }
                      maxLength={60}
                    />
                  </label>
               
                </div>
                <div className="row">
                  <label className="selectLabel">
                    Sort Order
                    <input
                      type="text"
                      name="category_title"
                      className="numberInput"
                      value={data?.sort_order}
                      onChange={(e) =>
                        setdata({
                          ...data,
                          sort_order: e.target.value,
                        
                        })
                      }
                      maxLength={60}
                    />
                  </label>
               
                </div>
           


               

                <div className="row">
                  
                  <label className="selectLabel">
                    Status
                    <div
                      className="flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="flex">
                        <input
                          type="radio"
                          name="sort_order"
                          className="numberInput"
                          checked={data.status}
                          style={{ height: "25px" }}
                          onClick={() =>
                            setdata((prev) => ({ ...prev, status: 1 }))
                          }
                        />
                        On
                      </div>
                      <div className="flex">
                        <input
                          type="radio"
                          name="sort_order"
                          className="numberInput"
                          checked={!data.status}
                          style={{ height: "25px" }}
                          onClick={() =>
                            setdata((prev) => ({ ...prev, status: 0 }))
                          }
                        />
                        Off
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <i style={{ color: "red" }}>
                {errMassage === "" ? "" : "Error: " + errMassage}
              </i>

              <button type="submit" className="submit">
                Save changes
              </button>
            </form>
          </div>
          <button onClick={onSave} className="closeButton">
            x
          </button>
        </div>
      </div>
    </div>
  );
}
function DeleteItemPopup({ onSave, popupInfo, setItemsData }) {
  const [errMassage, setErrorMassage] = useState("");
  const [loading, setLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios({
        method: "delete",
        url: "/Category/deleteCategory",
        data: { category_uuid: popupInfo.category_uuid },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.success) {
      
        onSave();
      }
    } catch (err) {
      console.log(err);
      setErrorMassage("Order already exist");
    }
    setLoading(false);
  };

  return (
    <div className="overlay">
      <div className="modal" style={{ width: "fit-content" }}>
        <div
          className="content"
          style={{
            height: "fit-content",
            padding: "20px",
            width: "fit-content",
          }}
        >
          <div style={{ overflowY: "scroll" }}>
            <form className="form" onSubmit={submitHandler}>
              <div className="row">
                <h1>Delete Items</h1>
              </div>
              <div className="row">
                <h1>{popupInfo.category_title}</h1>
              </div>

              <i style={{ color: "red" }}>
                {errMassage === "" ? "" : "Error: " + errMassage}
              </i>
              <div className="flex" style={{ justifyContent: "space-between" }}>
                {loading ? (
                  <button
                    className="submit"
                    id="loading-screen"
                    style={{ background: "red", width: "120px" }}
                  >
                    <svg viewBox="0 0 100 100">
                      <path
                        d="M10 50A40 40 0 0 0 90 50A40 44.8 0 0 1 10 50"
                        fill="#ffffff"
                        stroke="none"
                      >
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          dur="1s"
                          repeatCount="indefinite"
                          keyTimes="0;1"
                          values="0 50 51;360 50 51"
                        ></animateTransform>
                      </path>
                    </svg>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="submit"
                    style={{ background: "red" }}
                  >
                    Confirm
                  </button>
                )}
                <button type="button" className="submit" onClick={onSave}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
