import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DOMPurify from "dompurify";
import draftToHtml from 'draftjs-to-html';
import { convertToHTML } from "draft-convert";

function TextFeild({ convertedContent, setConvertedContent ,editorState, setEditorState }) {


  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    let css = editorState.getCurrentInlineStyle();
    console.log(css);
    setConvertedContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }, [editorState]);
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  return (
    <div>
      <div
        style={{
          border: "1px solid black",
          padding: "2px",
          minHeight: "300px",
        }}
      >
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
        />
      </div>
      
    </div>
  );
}
export default TextFeild;
