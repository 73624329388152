import React from 'react';
import InstagramEmbed from './InstagramEmbeded';

const DynamicContent = ({ content }) => {
  const parseContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    const elements = Array.from(doc.body.childNodes).map((node, index) => {
      if (node.nodeName === 'IFRAME') {
        let src = node.getAttribute('src');
        if (src.includes('youtube.com')) {
          const videoId = src.split('v=')[1];
          const embedUrl = `https://www.youtube.com/embed/${videoId}`;
          return (
            <div key={index} className="video-container">
              <iframe
                width="560"
                height="315"
                src={embedUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube video"
              ></iframe>
            </div>
          );
        } else if (src.includes('instagram.com')) {
          return (
            <div key={index} className="instagram-container">
              <InstagramEmbed url={src} />
            </div>
          );
        }
      } else {
        return <p key={index}>{node.textContent}</p>;
      }
    });

    return elements;
  };

  return <div>{parseContent(content)}</div>;
};

export default DynamicContent;
