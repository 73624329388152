import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ViewGridIcon } from "@heroicons/react/solid";
import { BarChart } from "@mui/icons-material";
import "../css/App.css"
const NavLink = ({
  title,
  icon,
  menuList,
  setIsItemAvilableOpen,
  isItemAvilableOpen,
  draggable,
  href,
  setIsDailyReportSummary,
  isDailyReportSummary,
  isMealSchedulingOpen,
  setIsMealSchedulingOpen,
}) => {


  let restrictions = JSON.parse(sessionStorage.getItem("restrictions")) || []

  function categoryFilterHandler(e) {
    Array.from(document.getElementsByClassName('highlighted-card'))?.forEach(i => i.classList?.remove('highlighted-card'))
    
  };

  const noWarning = () => {
  
  }

  // console.log(menuList)
  const [menuVisible, setMenuVisible] = useState(false)

  return (
    <Link to={
      restrictions?.length
        && +restrictions[0].other_setup_page_setup === 0
        && title === "Setup" ? { pathname: "#" } : { pathname: href }
    } className="nav_link_container"
      onClick={restrictions?.length
        && +restrictions[0].other_setup_page_setup === 0
        && title === "Setup" ? noWarning : () => { }
      }>
      <div
        className={`nav-link`}
        draggable={draggable}
        onClick={window.location.pathname.includes("/page2") ? categoryFilterHandler : () => menuList && setMenuVisible(!menuVisible)}
        onMouseLeave={e => setMenuVisible(false)}
        id={`item-category-${title?.toLowerCase()}`}
      >
        <>
          {icon}
          <p>
            {draggable && (
              <ViewGridIcon
                style={{
                  minWidth: "1rem",
                  maxWidth: "1rem",
                  marginRight: 10,
                  cursor: "move",
                }}
              />
            )}
            <span className={`nav_title ${window.location.pathname.includes("/page2") ? "page2" : ""}`}>{title?.slice(0, 31)}
              {title?.length > 32 && "..."}
            </span>
          </p>
        </>
        {/* Submenu popup*/}
        {menuList && (
          <div className="menu" style={{ display: menuVisible ? 'block' : 'none' }}>
            {menuList.filter(a => a).map((menu) => (
              <div
                className="item"
                key={Math.random()}
                onClick={() => {
                  return (
                    menu.name === "Meal Calendar" ?
                      setIsMealSchedulingOpen(!isMealSchedulingOpen) :
                      menu.name === "Item Availability" ? +restrictions[0]?.other_quick_access_item_availability === 0
                        ? noWarning() :
                        setIsItemAvilableOpen(!isItemAvilableOpen) :
                        menu.name === "Daily Summary" ? +restrictions[0]?.other_report_Daily_summary === 0
                          ? "" :
                          setIsDailyReportSummary(!isDailyReportSummary)
                   
                            : ''
                  )
                }}
                style={{position:"relative"}}
              >
                {
                  (menu.name === "Customers" && +restrictions[0]?.other_report_customer === 0)
                    || (menu.name === "Menu and Seats" && +restrictions[0]?.other_menu_and_seat === 0)
                    || (menu.name === "User" && +restrictions[0]?.other_users === 0)
                    ? <Link className="Link" to={"#"} onClick={noWarning}>{menu.name}</Link>
                    : <Link className="Link" to={menu.link}>{menu.name}</Link>
                }
                {menu?.graph?<BarChart style={{position:"absolute",right:"0",bottom:"8px"}}/>:""}
              </div>
            ))}
          </div>
        )}
      </div>
    </Link >
  );
};

export default NavLink;