import React from "react";

import "../css/App.css"
import "../css/user.css"
import "../css/NavPages.css"
import Layout from "./Layout";
const Dashboard = () => {
  return (
    <Layout>
      <div style={{ display: "flex", height: "calc(100vh - 90px)" }}></div>
    </Layout>
  );
};

export default Dashboard;
